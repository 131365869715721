import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'app/_models/globals';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'app/_services/notification.service';
import { SupportHoldingsBillingDataManagementService } from '../../billing-data-management.service';

import { zeraHoraMinutoSegundo } from 'app/_utils/general-utils';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { HoldingSupplier } from '../../_models/holdingSupplier';
import { HoldingPayment } from '../../_models/holdingPayment';

declare const $: any;
declare const formatDateToDDMMYYYY: any;

@Component({ selector: 'app-holding-manage-payment', templateUrl: './holding-manage-payment.component.html', styleUrls: ['./holding-manage-payment.component.scss'] })
export class SupportHoldingManagePaymentComponent {
    @ViewChild('templateHoldingManagePayment') template: TemplateRef<any>;
    @Input() holding: HoldingSupplier;

    modalRef: BsModalRef;
    paymentForm: UntypedFormGroup;

    pastPayments: HoldingPayment[] = []

    isLoading: boolean = false;
    currentStartDate: Date;
    minStartDate: NgbDate;
    minEndDate: NgbDate;

    constructor(private holdingService: SupportHoldingsBillingDataManagementService, private notificationService: NotificationService, private ngxLoader: NgxUiLoaderService, private modalService: BsModalService, private formBuilder: UntypedFormBuilder, private globals: Globals) { }

    addPayment(date: Date) {
        this.ngxLoader.startLoader('loader-manage-payment');

        this.holdingService.createHoldingPayment(this.holding.holdingId, date).subscribe({
            next: (response: ApiResponseModel<HoldingSupplier>) => {
                if (response.result) {
                    this.holding = response.result;
                    this.currentStartDate = date;
                    this.handleInputEnable();

                    this.ngxLoader.stopLoader('loader-manage-payment');
                    this.notificationService.showSuccessToastr('support.manage-holdings.manage.payment-manage.messages.add.success', { translateMessage: true });
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-manage-payment');
                this.notificationService.showErrorToastr('support.manage-holdings.manage.payment-manage.messages.add.error', { translateMessage: true });
            }
        })
    }

    async close(): Promise<void> {
        this.modalRef.hide()
    }

    createDate(inputDate: { year: number, month: number, day: number } | string): Date | undefined {
        if (typeof inputDate != 'string') {
            return new Date(inputDate.year, inputDate.month - 1, inputDate.day);
        }

        if (inputDate != '' && inputDate.checkIfHasTwoSlashes()) {
            var dateParts = inputDate.split("/").map(d => parseInt(d));

            return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        }

        return null;
    }

    dateSelect(date: NgbDate, key: string): void {
        const formControl: any = {};
        formControl[key] = formatDateToDDMMYYYY(this.createDate(date));
        this.paymentForm.patchValue(formControl);
    }

    enablePaymentStartDate() {
        this.setMinStartDate();

        this.paymentForm.controls.startDate.setValue("");
        this.paymentForm.controls.endDate.setValue("");

        this.paymentForm.controls.startDate.enable();
        this.paymentForm.controls.endDate.disable();

        $('#start-date').focus();
    }

    enablePaymentEndDate() {
        this.setMinEndDate();

        this.paymentForm.controls.startDate.disable();
        this.paymentForm.controls.endDate.enable();
        $('#end-date').focus();
    }

    endPayment(date: Date) {
        this.ngxLoader.startLoader('loader-manage-payment');

        this.holdingService.addPaymentEndDate(this.holding.holdingId, date).subscribe({
            next: (response: ApiResponseModel<{ holding: HoldingSupplier, payment: HoldingPayment }>) => {
                if (response.result) {
                    this.currentStartDate = null;
                    this.holding = response.result.holding;

                    var payment = response.result.payment;
                    var startDate = new Date(payment.startDate);
                    var endDate = new Date(payment.endDate);
                    endDate = new Date(endDate.setHours(endDate.getHours() + 3));

                    this.pastPayments.unshift({ paymentId: payment.paymentId, startDate, endDate });

                    this.handleInputEnable();

                    this.ngxLoader.stopLoader('loader-manage-payment');
                    this.notificationService.showSuccessToastr('support.manage-holdings.manage.payment-manage.messages.end.success', { translateMessage: true });
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-manage-payment');
                this.notificationService.showErrorToastr('support.manage-holdings.manage.payment-manage.messages.end.error', { translateMessage: true });
            }
        })
    }

    handleAddPayment() {
        const formValue = this.paymentForm.getRawValue();

        let startDate = this.createDate(formValue.startDate);
        let endDate = this.createDate(formValue.endDate);

        if (this.currentStartDate == null) {
            var previousPayment = this.pastPayments[0];

            if (startDate == null || startDate < previousPayment?.endDate) {
                this.enablePaymentStartDate();
                this.notificationService.showErrorToastr('support.manage-holdings.manage.payment-manage.form.error.start-date', { translateMessage: true });
                return;
            }

            return this.addPayment(startDate);
        }

        if (endDate == null || startDate > endDate) {
            this.enablePaymentEndDate();
            this.notificationService.showErrorToastr('support.manage-holdings.manage.payment-manage.form.error.end-date', { translateMessage: true });
            return;
        }

        return this.endPayment(endDate);
    }

    handleInputEnable() {
        if (this.currentStartDate != null)
            return this.enablePaymentEndDate();

        return this.enablePaymentStartDate();
    }

    open() {
        this.resetForm();
        let options: ModalOptions = { class: 'custom-modal', backdrop: 'static', keyboard: false };
        this.modalRef = this.modalService.show(this.template, options);

        if (this.pastPayments.length > 0) {
            this.paymentForm.setValue({
                startDate: this.currentStartDate ? formatDateToDDMMYYYY(this.currentStartDate) : '',
                endDate: ''
            });

            return this.handleInputEnable();
        }

        this.ngxLoader.startLoader('loader-manage-payment');

        this.holdingService.getHoldingPayments(this.holding.holdingId).subscribe({
            next: (response: ApiResponseModel<HoldingPayment[]>) => {
                response.result.forEach((payment) => {
                    payment.startDate = new Date(payment.startDate);

                    if (payment.endDate == null) {
                        this.currentStartDate = payment.startDate;
                        this.setMinEndDate();

                        return this.paymentForm.setValue({
                            startDate: formatDateToDDMMYYYY(payment.startDate),
                            endDate: ''
                        });
                    }

                    payment.endDate = new Date(payment.endDate);

                    this.pastPayments.push({ paymentId: payment.paymentId, startDate: payment.startDate, endDate: payment.endDate });
                })

                this.setMinStartDate();
                this.handleInputEnable();

                this.ngxLoader.stopLoader('loader-manage-payment');
            },
            error: error => {
                console.log(error);
                this.close();
                this.ngxLoader.stopLoader('loader-manage-payment');
                this.notificationService.showErrorToastr('support.manage-holdings.manage.payment-manage.messages.loading.error', { translateMessage: true });
            }
        })
    }

    removePayment(paymentId: number) {
        this.ngxLoader.startLoader('loader-manage-payment');

        this.holdingService.deleteHoldingPayment(this.holding.holdingId, paymentId).subscribe({
            next: (response: ApiResponseModel<HoldingSupplier>) => {
                if (response.result) {
                    this.holding = response.result;

                    let paymentIndex = this.pastPayments.findIndex(p => p.paymentId == paymentId);
                    this.pastPayments.splice(paymentIndex, 1);

                    if (paymentIndex == 0)
                        this.setMinStartDate();

                    this.ngxLoader.stopLoader('loader-manage-payment');
                    this.notificationService.showSuccessToastr('support.manage-holdings.manage.payment-manage.messages.remove.success', { translateMessage: true });
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-manage-payment');
                this.notificationService.showErrorToastr('support.manage-holdings.manage.payment-manage.messages.remove.error', { translateMessage: true });
            }
        })
    }

    resetForm() {
        this.paymentForm = this.formBuilder.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required]
        });
    }

    setMinStartDate() {
        var lastPayment = this.pastPayments[0];
        this.minStartDate = lastPayment ? { year: lastPayment.endDate.getFullYear(), month: lastPayment.endDate.getMonth() + 1, day: lastPayment.endDate.getDate() } as NgbDate : null;
    }

    setMinEndDate() {
        this.minEndDate = { year: this.currentStartDate.getFullYear(), month: this.currentStartDate.getMonth() + 1, day: this.currentStartDate.getDate() } as NgbDate;
    }
}
