import { Component, ViewChild } from '@angular/core';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { GetByPageResponse } from './_models/getByPageResponse';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'app/_services/notification.service';
import { Globals } from 'app/_models/globals';
import { HoldingSupplier } from './_models/holdingSupplier';
import { SupportHoldingsBillingDataManagementService } from './billing-data-management.service';
import { HoldingSupplierForEdit } from './_models/holdingSupplierForEdit';
import { SupportManageHoldingComponent } from './_components/holding-manage/holding-manage.component';

declare const isEmpty: any;
@Component({ selector: 'app-manage-holdings', templateUrl: './billing-data-management.component.html', styleUrls: ['./billing-data-management.component.scss'] })
export class SupportHoldingsBillingDataManagement {
    @ViewChild('templateHoldingManageComponent') public holdingManageComponent: SupportManageHoldingComponent;

    _searchString: string = '';
    isList: boolean = true;
    isLoading: boolean = false;
    actualPage: number = 0;
    selectedHoldingToEdit: HoldingSupplier = null;
    totalHoldings: number;
    holdings: HoldingSupplier[] = [];

    constructor(private supportManageHoldingsService: SupportHoldingsBillingDataManagementService, private route: ActivatedRoute, private notificationService: NotificationService, private ngxLoader: NgxUiLoaderService, private globals: Globals) {
    }

    ngOnInit() {
        let param_id = this.route.snapshot.params.id;

        if(!isEmpty(param_id))
            return this.getById(param_id);

        this.getByPage(0);
    }

    getById(holdingId: string) {
        this.ngxLoader.startLoader('loader-principal');

        this.supportManageHoldingsService.getById(holdingId).subscribe({
            next: (response: ApiResponseModel<HoldingSupplier>) => {
                let holding = response.result;

                if (holding) {
                    this.openHoldingEdition(holding);
                    this.ngxLoader.stopLoader('loader-principal');
                } else {
                    this.goBackToList();
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.notificationService.showErrorToastr(this.globals.translate('support.manage-holdings.messages.error.get-one'));
                this.goBackToList();
            }
        });
    }

    getByPage(pageNumber: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.supportManageHoldingsService.getByPage(pageNumber, this._searchString.toLowerCase()).subscribe({
            next: (response: ApiResponseModel<GetByPageResponse>) => {
                if (response && response.success) {
                    this.actualPage = pageNumber;
                    this.totalHoldings = response.result.totalHoldings;
                    this.holdings = response.result.holdings;
                } else {
                    this.actualPage = 0;
                    this.totalHoldings = 0;
                    this.holdings = [];
                    this.notificationService.showErrorToastr(this.globals.translate('support.manage-holdings.messages.error.list'));
                }
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.notificationService.showErrorToastr(this.globals.translate('support.manage-holdings.messages.error.list'));
            }
        });
    }

    goBackToList() {
        if (this.holdings.length == 0)
            this.getByPage(0);

        this.isList = true;
        this.selectedHoldingToEdit = null;
        history.pushState({}, '', '/suporte/fornecedores/holdings');
    }

    handleHoldingSave(holding: HoldingSupplierForEdit) {
        return this.saveHolding(holding);
    }

    openHoldingEdition(holding: HoldingSupplier) {
        this.selectedHoldingToEdit = holding;
        history.pushState({}, '', '/suporte/fornecedores/holdings/edit/' + holding.holdingId);
        this.isList = false;
    }

    saveHolding(holding: HoldingSupplierForEdit) {
        this.ngxLoader.startLoader('loader-principal');

        this.supportManageHoldingsService.editHoldingBillingData(holding).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response.success) {
                    this.getByPage(0);
                    this.goBackToList();
                } else {
                    this.isLoading = false;
                    this.notificationService.showErrorToastr(this.globals.translate('support.manage-holdings.messages.error.edit'));
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-principal');
                this.isLoading = false;
                this.notificationService.showErrorToastr(this.globals.translate('support.manage-holdings.messages.error.edit'));
            }
        });
    }

    get searchTerm(): string {
        return this._searchString;
    }

    set searchTerm(value: string) {
        this._searchString = value;
        this.getByPage(0);
    }
}
