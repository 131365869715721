import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NotificationService } from 'app/_services/notification.service';
import { Globals } from 'app/_models/globals';
import { Categoria } from 'app/buyer/mercado-partsfy/list/_models/Categoria';
import { HoldingSupplier } from '../../_models/holdingSupplier';
import { HoldingSupplierForEdit, holdingSupplierForEditFactory } from '../../_models/holdingSupplierForEdit';
import { PlanEnum } from 'app/_models/plans';
import { HoldingPaymentType } from 'app/_models/holdingPaymentType';
import { SupportHoldingCustomComissionComponent } from '../holding-custom-comission/holding-custom-comission.component';
import { SupportHoldingManagePaymentComponent } from '../holding-manage-payment/holding-manage-payment.component';

@Component({ selector: 'app-holding-manage', templateUrl: './holding-manage.component.html', styleUrls: ['./holding-manage.component.scss'] })
export class SupportManageHoldingComponent {
    @Input() holding?: HoldingSupplier = null;
    @Input() partsfyCashback: number = 20;
    @Input() categories: Categoria[] = [];
    @Output() saveHolding = new EventEmitter<HoldingSupplierForEdit>();
    @Output() goBackToList = new EventEmitter<null>();
    @ViewChild('templateCropperModal') templateCropperModal: TemplateRef<any>;
    @ViewChild('categorySelect') categorySelect: ElementRef;
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('templateHoldingCustomComission') public repairersComissionManageModal: SupportHoldingCustomComissionComponent;
    @ViewChild('templateHoldingManagePayment') public holdingManagePaymentModal: SupportHoldingManagePaymentComponent;

    allowKeysInput = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    cashbackValue: number = 0;
    finalPrice: number = 0;
    holdingManageForm: UntypedFormGroup;
    isLoading: boolean = false;

    paymentTypeOptions = [
        { value: HoldingPaymentType.Comission, label: 'support.manage-holdings.manage.payment-types.comission' },
        { value: HoldingPaymentType.Quotation, label: 'support.manage-holdings.manage.payment-types.quotation' },
        { value: HoldingPaymentType.Order, label: 'support.manage-holdings.manage.payment-types.order' }
    ];

    planOptions = [
        { value: PlanEnum.Popular, label: 'support.manage-holdings.manage.plan-types.popular' },
        { value: PlanEnum.V8, label: 'support.manage-holdings.manage.plan-types.v8' },
        { value: PlanEnum.Turbo, label: 'support.manage-holdings.manage.plan-types.turbo' }
    ];

    validationMessages: any;
    isPaymentTypeComission: boolean = false;

    constructor(private formBuilder: UntypedFormBuilder, private notificationService: NotificationService, private globals: Globals) {
        this.holdingManageForm = this.formBuilder.group({
            plan: [""],
            paymentType: [""],
            monthlyFee: [0, [Validators.max(99999.99)]],
            comissionTax: [0, [Validators.required, Validators.min(0), Validators.max(99)]],
            customCashback: ["", Validators.required],
            supplierTax: [0, [Validators.min(0.01), Validators.max(99.99)]],
            cashbackDefault: ["", Validators.required],
            cashbackDefaultValue: [0, Validators.required]
        });

        this.validationMessages = {
            tax: {
                required: this.globals.translate('support.manage-holdings.validation-messages.tax')
            },
            supplierTax: {
                min: this.globals.translate('support.manage-holdings.validation-messages.supplier-tax-min'),
            },
            cashbackDefaultValue: {
                required: this.globals.translate('support.manage-holdings.validation-messages.cashback-default-value')
            }
        };
    }

    ngOnInit() {
        if (this.holding) {
            this.holdingManageForm.setValue({
                plan: this.holding.plan,
                paymentType: this.holding.paymentType,
                monthlyFee: this.holding.monthlyFee ?? 0,
                comissionTax: this.holding.opportunityCost ?? 0,
                customCashback: this.holding.hasCashback,
                supplierTax: this.holding.tax,
                cashbackDefault: this.holding.rebate > 0,
                cashbackDefaultValue: this.holding.rebate
            });

            this.onCustomCashbackChange();
            this.onCashbackDefaultChange();
            this.onPaymentTypeChange();
        }
    }

    close() {
        this.goBackToList.emit();
    }

    save() {
        if (!this.holdingManageForm.touched) {
            this.close();
            return;
        }

        if (!this.isFormValid())
            return;

        this.isLoading = true;

        const formValue = this.holdingManageForm.value;
        const holdingSupplier = holdingSupplierForEditFactory(this.holding.holdingId, formValue.customCashback, formValue.monthlyFee, formValue.comissionTax, formValue.paymentType, formValue.plan, formValue.cashbackDefaultValue, formValue.supplierTax);

        try {
            this.saveHolding.emit(holdingSupplier);
        } finally {
            this.isLoading = false;
        }
    }

    isFormValid() {
        Object.keys(this.holdingManageForm.controls).forEach(field => {
            const control = this.holdingManageForm.get(field);
            control?.markAsTouched({ onlySelf: true });
        });

        this.holdingManageForm.get('cashbackDefaultValue')?.setErrors(null);

        let cashbackDefault = this.holdingManageForm.get('cashbackDefault')?.value;

        if (cashbackDefault === true) {
            let cashbackDefaultValue = this.holdingManageForm.get('cashbackDefaultValue')?.value;
            let plan = this.holdingManageForm.get('plan')?.value;

            if (plan === PlanEnum.Popular && cashbackDefaultValue !== 0 && cashbackDefaultValue !== 1) {
                this.holdingManageForm.get('cashbackDefaultValue')?.setErrors({ invalidCashbackDefaultValue: true });
                this.notificationService.showErrorToastr(this.globals.translate('support.manage-holdings.messages.error.plan-popular-error'));
                return false;
            }

            if (cashbackDefaultValue < 0 || cashbackDefaultValue > 10) {
                this.holdingManageForm.get('cashbackDefaultValue')?.setErrors({ invalidCashbackDefaultValue: true });
                this.notificationService.showErrorToastr(this.globals.translate('support.manage-holdings.messages.error.plan-turbo-or-v8-error'));
                return false;
            }
        }

        if (this.holdingManageForm.valid)
            return true;

        Object.keys(this.validationMessages).forEach(field => {
            const control = this.holdingManageForm.get(field);
            const errorMessages = this.validationMessages[field];

            if (control?.invalid) {
                Object.keys(errorMessages).forEach(errorKey => {
                    if (control.errors?.[errorKey]) {
                        this.notificationService.showErrorToastr(errorMessages[errorKey]);
                    }
                });
            }
        });

        return false;
    }

    onCustomCashbackChange() {
        let customCashbackSelected = this.holdingManageForm.controls['customCashback'].value === true;
        let supplierTaxControl = this.holdingManageForm.get('supplierTax');

        if (customCashbackSelected) {
            supplierTaxControl?.enable();
        } else {
            supplierTaxControl?.disable();
            supplierTaxControl.setValue(0);
        }
    }

    onCashbackDefaultChange() {
        let cashbackDefaultSelected = this.holdingManageForm.controls['cashbackDefault'].value === true;
        let cashbackDefaultValueControl = this.holdingManageForm.get('cashbackDefaultValue');

        if (cashbackDefaultSelected) {
            cashbackDefaultValueControl?.enable();
        } else {
            cashbackDefaultValueControl?.disable();
            cashbackDefaultValueControl.setValue(0);
        }
    }

    onPaymentTypeChange() {
        this.isPaymentTypeComission = this.holdingManageForm.controls['paymentType'].value === HoldingPaymentType.Comission;
    }

    openManagePaymentModal() {
        this.holdingManagePaymentModal.open();
    }

    preventExceedingTwoDigits(event: KeyboardEvent) {
        let input = event.target as HTMLInputElement;

        if (input.value.length >= 2 && !this.allowKeysInput.includes(event.key)) {
            event.preventDefault();
        }
    }
}
