<ng-template #templateHoldingCustomComission>
    <div class="modal-custom-comission">
        <div class="modal-header">
            <div class="d-flex flex-column">
                <h4 class="modal-title">{{ 'support.manage-holdings.manage.comission-manage.header.title' | translate }}</h4>
                <span class="modal-subtitle">
                    <span>{{ 'support.manage-holdings.manage.comission-manage.header.subtitle' | translate: { name: holding.name, value: (holding.opportunityCost ?? 0 | number: ( holding.tax < 10 ? '.0-2' : '0.0-1')) } }}</span>
                </span>
            </div>
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="close();"></button>
        </div>
        <div class="modal-body">
            <div class="content">
                <form [formGroup]="editionForm">
                    <div class="repairer">
                        <label for="repairer">{{ 'support.manage-holdings.manage.comission-manage.form.repairer.label' | translate }}</label>
                        <select #categorySelect class="form-select" formControlName="repairer" [ngClass]="{ 'is-invalid': editionForm.controls.repairer.invalid && editionForm.controls.repairer.touched }">
                            <option value="" disabled selected hidden>{{ 'support.manage-holdings.manage.comission-manage.form.repairer.placeholder' | translate }}</option>

                            @for (repairer of repairers; track repairer.repairerHoldingId) {
                                <option [value]="repairer.repairerHoldingId" [disabled]="repairer.isBlocked">{{ repairer.repairerName }}</option>
                            }
                        </select>
                    </div>
                    <div class="comission">
                        <label for="comission">{{ 'support.manage-holdings.manage.comission-manage.form.comission.label' | translate }}</label>
                        <div class="input-with-icon percentage">
                            <input type="text" class="form-control" name="comission" formControlName="comission" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99" maxlength="4"/>
                        </div>
                    </div>
                    <div class="add-button">
                        <button type="submit" customButton styleType="secondary" [label]="'support.manage-holdings.manage.comission-manage.form.button' | translate" [isLoading]="false" (click)="addRepairerComission()"></button>
                    </div>
                </form>
                @if (customComissions?.length <= 0) {
                    <div class="empty-list">
                        <h4 class="vazio">{{ 'support.manage-holdings.manage.comission-manage.list.empty' | translate }}</h4>
                    </div>
                } @else {
                    <ng-scrollbar>
                        @for (repairerComission of customComissions; track repairerComission.repairerHoldingId) {
                            <div class="repairer-comission">
                                <div class="col-repairer-name">
                                    <div class="col-content">{{repairerComission.repairerName}}</div>
                                </div>
                                <div class="col-repairer-comission">
                                    <div class="col-content text-end">{{repairerComission.comissionValue}}%</div>
                                </div>
                                <div class="col-repairer-delete">
                                    <div class="delete-button" (click)="removeRepairerComission(repairerComission.repairerHoldingId)">
                                        <i-feather name="x"></i-feather>
                                    </div>
                                </div>
                            </div>
                        }
                    </ng-scrollbar>
                }
            </div>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'loader-repairers-comission'"></ngx-ui-loader>
</ng-template>
