<main class="listagem">
    @if (holdings?.length <= 0) {
        <h4 class="vazio">{{ 'support.manage-holdings.list.empty-list' | translate }}</h4>
    } @else {
        <div class="table">
            <div>
                <section class="d-flex flex-wrap striped hover">
                    <header>
                        <div class="col-1-25 text-center">{{ 'support.manage-holdings.list.table-header.id' | translate }}</div>
                        <div class="col-2">{{ 'support.manage-holdings.list.table-header.name' | translate }}</div>
                        <div class="col-2">{{ 'support.manage-holdings.list.table-header.type' | translate }}</div>
                        <div class="col-2">{{ 'support.manage-holdings.list.table-header.payment-type' | translate }}</div>
                        <div class="col-2">{{ 'support.manage-holdings.list.table-header.cashback' | translate }}</div>
                        <div class="col-2">{{ 'support.manage-holdings.list.table-header.is-billable' | translate }}</div>
                        <div class="col-0-5 text-center">{{ 'support.manage-holdings.list.table-header.actions' | translate }}</div>
                    </header>
                    <ng-scrollbar>
                        @for (holding of holdings; track holding) {
                            <div class="linha">
                                <div class="col-1-25 text-center">{{holding.holdingId}}</div>
                                <div class="col-2">{{holding.name}}</div>
                                <div class="col-2">{{holding.holdingTypeDescription}}</div>
                                <div class="col-2">{{holding.paymentTypeDescription}}</div>
                                <div class="col-2">{{holding.cashbackTypeDescription}}</div>
                                <div class="col-2">
                                    <i-feather [name]="holding.isBillable ? 'check' : 'x'" class="isBillable"></i-feather>
                                </div>
                                <div class="col-0-5 text-center actions">
                                    <i-feather name="edit" class="edit" (click)="selectHoldingToEdit(holding)"></i-feather>
                                </div>
                            </div>
                        }
                    </ng-scrollbar>
                    <footer>
                        <mat-paginator
                                    [showFirstLastButtons]="true"
                                    [hidePageSize]="true"
                                    [length]="totalHoldings"
                                    [pageSize]="10"
                                    [pageIndex]="pageNumberController"
                                    (page)="changePage($event)">
                        </mat-paginator>
                    </footer>
                </section>
            </div>
        </div>
    }
</main>
