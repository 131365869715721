import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { HoldingSupplier } from '../../_models/holdingSupplier';

@Component({ selector: 'app-holdings-list', templateUrl: './holdings-list.component.html', styleUrls: ['./holdings-list.component.scss'] })
export class SupportHoldingsListComponent {
    @Input() pageNumberController: number;
    @Input() holdings: HoldingSupplier[];
    @Input() totalHoldings: number;
    @Output() editHolding = new EventEmitter<any>();
    @Output() pageChange = new EventEmitter<number>();

    constructor() { }

    selectHoldingToEdit(holding: any) {
        this.editHolding.emit(holding);
    }

    changePage($event: PageEvent) {
        this.pageChange.emit($event.pageIndex);
    }
}
