<div class="create-update">
    <ng-scrollbar>
        <h3>{{ 'support.manutencao.cadastro-novidades.criacao.subtitulo' | translate}}</h3>
        <div class="form">
            <div class="form-header">
                <div class="date-input">
                    <label for="date">{{ 'support.manutencao.cadastro-novidades.criacao.labels.data' | translate}}</label>
                    <div class="input-group input-group-sm">
                        <input class="form-control form-control-sm" placeholder="dd/mm/yyyy" [(ngModel)]="datePicked" name="date" ngbDatepicker #d="ngbDatepicker" [firstDayOfWeek]="7" [minDate]="calendarMinDate" [maxDate]="calendarMaxDate">
                        <div class="input-group-text cursor-pointer" (click)="d.toggle()"><i class="fa fa-calendar" aria-hidden="true"></i></div>
                    </div>
                </div>
                @if (isEditMode) {
                    <div class="is-active-input">
                        <label for="is-active">{{ 'support.manutencao.cadastro-novidades.criacao.labels.publicado' | translate}}</label>
                        <div class="form-check c-checkbox needsclick checkboxcot">
                            <label class="needsclick">
                                <input type="checkbox" class="needsclick" [(ngModel)]="selectedUpdate.is_active"/>
                                <span class="fa fa-check"></span>
                            </label>
                        </div>
                    </div>
                }
                <div class="public-input">
                    <label>{{ 'support.manutencao.cadastro-novidades.criacao.labels.publicos.titulo' | translate}}</label>
                    <div class="form-check c-checkbox needsclick checkboxcot">
                        <label class="needsclick">
                            <input [value]="simplifiedRolesIdsEnum.Central" type="checkbox" class="needsclick" (click)="selectPublic($event);" [ngModel]="rolesSelected.central"/>
                            <span class="fa fa-check"></span> {{ 'support.manutencao.cadastro-novidades.criacao.labels.publicos.central' | translate}}
                        </label>
                    </div>
                    <div class="form-check c-checkbox needsclick checkboxcot">
                        <label class="needsclick">
                            <input [value]="simplifiedRolesIdsEnum.Comprador" type="checkbox" class="needsclick" (click)="selectPublic($event);" [ngModel]="rolesSelected.comprador"/>
                            <span class="fa fa-check"></span> {{ 'support.manutencao.cadastro-novidades.criacao.labels.publicos.comprador' | translate}}
                        </label>
                    </div>
                    <div class="form-check c-checkbox needsclick checkboxcot">
                        <label class="needsclick">
                            <input [value]="simplifiedRolesIdsEnum.DonoDaOficina" type="checkbox" class="needsclick" (click)="selectPublic($event);" [ngModel]="rolesSelected.dono"/>
                            <span class="fa fa-check"></span> {{ 'support.manutencao.cadastro-novidades.criacao.labels.publicos.dono' | translate}}
                        </label>
                    </div>
                    <div class="form-check c-checkbox needsclick checkboxcot">
                        <label class="needsclick">
                            <input [value]="simplifiedRolesIdsEnum.GestorDePecas" type="checkbox" class="needsclick" (click)="selectPublic($event);" [ngModel]="rolesSelected.gestor"/>
                            <span class="fa fa-check"></span> {{ 'support.manutencao.cadastro-novidades.criacao.labels.publicos.gestor' | translate}}
                        </label>
                    </div>
                    <div class="form-check c-checkbox needsclick checkboxcot">
                        <label class="needsclick">
                            <input [value]="simplifiedRolesIdsEnum.Vendedor" type="checkbox" class="needsclick" (click)="selectPublic($event);" [ngModel]="rolesSelected.vendedor"/>
                            <span class="fa fa-check"></span> {{ 'support.manutencao.cadastro-novidades.criacao.labels.publicos.vendedor' | translate}}
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-body">
                <h4>{{ 'support.manutencao.cadastro-novidades.criacao.labels.titulo' | translate}}</h4>
                <input class="form-control" [(ngModel)]="selectedUpdate.title" name="title">
                <hr>
                <h4>{{ 'support.manutencao.cadastro-novidades.criacao.labels.features.titulo' | translate}}</h4>
                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="featuresEditor" [toolbar]="toolbar"> </ngx-editor-menu>
                    <ngx-editor
                        [editor]="featuresEditor"
                        [(ngModel)]="selectedUpdate.features_info"
                        [disabled]="false"
                        [placeholder]="'support.manutencao.cadastro-novidades.criacao.labels.features.placeholder' | translate"
                    ></ngx-editor>
                </div>

                <h4>{{ 'support.manutencao.cadastro-novidades.criacao.labels.bug-fixes.titulo' | translate}}</h4>
                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="bugsEditor" [toolbar]="toolbar"> </ngx-editor-menu>
                    <ngx-editor
                        [editor]="bugsEditor"
                        [(ngModel)]="selectedUpdate.bugs_info"
                        [disabled]="false"
                        [placeholder]="'support.manutencao.cadastro-novidades.criacao.labels.bug-fixes.placeholder' | translate"
                    ></ngx-editor>
                </div>
            </div>
            <div class="form-footer">
                <button class="btn btn-light" (click)="close()">{{ 'support.manutencao.cadastro-novidades.criacao.botoes.cancelar' | translate}}</button>
                <button class="btn btn-primary" (click)="saveUpdate()">{{ ('support.manutencao.cadastro-novidades.criacao.botoes.' + (isEditMode ? 'salvar' : 'adicionar')) | translate}}</button>
            </div>
        </div>
    </ng-scrollbar>
</div>
