<div class="produtos-commerce">
    <app-header
        [hasBackButton]="!isList"
        [hasButtons]="true"
        [hasSearchButton]="isList"
        [hasMinCharacters]="false"
        [searchInput]="searchTerm"
        [searchPlaceholder]="isList ? ('support.manage-holdings.header.search-placeholder' | translate) : null"
        [title]="(('support.manage-holdings.header.titles.' + (isList ? 'list' : 'edition')) | translate: { name: selectedHoldingToEdit?.name })"
        (onSearch)="searchTerm = $event"
        (onGoBack)="goBackToList()"
    >
        @if (!isList) {
            <button class="payment-manage-button" customButton styleType="secondary" iconName="edit" [label]="'Gerenciar pagamento' | translate" [isLoading]="false" (click)="holdingManageComponent.openManagePaymentModal()"></button>
        }
    </app-header>

    @if (isList) {
        <app-holdings-list [holdings]="holdings" [totalHoldings]="totalHoldings" [pageNumberController]="actualPage" (editHolding)="openHoldingEdition($event)" (pageChange)="getByPage($event)"></app-holdings-list>
    }@else {
        <app-holding-manage #templateHoldingManageComponent [holding]="selectedHoldingToEdit" (goBackToList)="goBackToList()" (saveHolding)="handleHoldingSave($event)"></app-holding-manage>
    }
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
