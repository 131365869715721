<ng-template #templateHoldingManagePayment>
    <div class="modal-manage-payment">
        <div class="modal-header">
            <div class="d-flex flex-column">
                <h4 class="modal-title">{{ 'support.manage-holdings.manage.payment-manage.header.title' | translate }}</h4>
                <span class="modal-subtitle">
                    <span>{{holding.name}} - {{ 'support.manage-holdings.manage.payment-manage.header.subtitle.' + (holding.isBillable ? 'pagante' : 'nao-pagante') | translate }} </span>
                </span>
            </div>
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="close();"></button>
        </div>
        <div class="modal-body">
            <div class="content">
                <form [formGroup]="paymentForm">
                    <div class="date-input">
                        <label for="startDate">
                            {{ 'support.manage-holdings.manage.payment-manage.form.start-date.label' | translate }}
                            <i-feather name="info" tooltip="{{'support.manage-holdings.manage.payment-manage.form.tooltip.start-date' | translate}}" [zIndex]="2000"></i-feather>
                        </label>

                        <div class="input-group">
                            <input type="hidden" (dateSelect)="dateSelect($event, 'startDate')" name="ds" ngbDatepicker #ds="ngbDatepicker" [firstDayOfWeek]="7" [positionTarget]="startDateInput" [minDate]="minStartDate">
                            <input type="text" class="form-control" #startDateInput id="start-date" placeholder="dd/mm/yyyy" formControlName="startDate" [leadZeroDateTime]="true" mask="d0/M0/0000" [dropSpecialCharacters]="false" />
                            <div class="input-group-text cursor-pointer" (click)="ds.toggle()"><i class="fa fa-calendar" aria-hidden="true"></i></div>
                        </div>
                    </div>
                    <div class="date-input">
                        <label for="endDate">
                            {{ 'support.manage-holdings.manage.payment-manage.form.end-date.label' | translate }}
                            <i-feather name="info" [tooltip]="'support.manage-holdings.manage.payment-manage.form.tooltip.end-date' | translate" [zIndex]="2000"></i-feather>
                        </label>

                        <div class="input-group">
                            <input type="hidden" (dateSelect)="dateSelect($event, 'endDate')" name="de" ngbDatepicker #de="ngbDatepicker" [firstDayOfWeek]="7" [positionTarget]="endDateInput"  [minDate]="minEndDate">
                            <input type="text" class="form-control" #endDateInput id="end-date" placeholder="dd/mm/yyyy" formControlName="endDate" [leadZeroDateTime]="true" mask="d0/M0/0000" [dropSpecialCharacters]="false" />
                            <div class="input-group-text cursor-pointer" (click)="de.toggle()"><i class="fa fa-calendar" aria-hidden="true"></i></div>
                        </div>
                    </div>
                    <div class="add-button">
                        <button type="submit" customButton styleType="secondary" [label]="'support.manage-holdings.manage.payment-manage.form.button' | translate" [isLoading]="false" (click)="handleAddPayment()"></button>
                    </div>
                </form>
                @if (pastPayments?.length <= 0) {
                    <div class="empty-list">
                        @if (!currentStartDate) {
                            <h4 class="vazio">{{ 'support.manage-holdings.manage.payment-manage.list.empty' | translate }}</h4>
                        }
                    </div>
                } @else {
                    <ng-scrollbar>
                        @for (paymentDate of pastPayments; track paymentDate) {
                            <div class="payment-date">
                                <div class="date">
                                    <div class="input-group">
                                        <input class="form-control" [value]="paymentDate.startDate | date:'dd/MM/yyyy'" disabled>
                                        <div class="input-group-text disabled"><i class="fa fa-calendar" aria-hidden="true"></i></div>
                                    </div>
                                </div>
                                <div class="date">
                                    <div class="input-group">
                                        <input class="form-control" [value]="paymentDate.endDate|  date:'dd/MM/yyyy'" disabled>
                                        <div class="input-group-text disabled"><i class="fa fa-calendar" aria-hidden="true"></i></div>
                                    </div>
                                </div>
                                <div class="col-repairer-delete">
                                    <div class="delete-button" (click)="removePayment(paymentDate.paymentId)">
                                        <i-feather name="x"></i-feather>
                                    </div>
                                </div>
                            </div>
                        }
                    </ng-scrollbar>
                }
            </div>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'loader-manage-payment'"></ngx-ui-loader>
</ng-template>
